(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/helpers.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/helpers.js');

(function(svs) {
  var path; var productIds; var resolveProductIdFunc;

  if (typeof window === 'undefined') {
    path = require('path');
    resolveProductIdFunc = require(path.join(__dirname, 'resolve-product-id'));
    productIds = require(path.join(__dirname, 'product-ids'));
    module.exports = {
      isStryktipset: function(anyInput) {
        return isStryktipset(anyInput, productIds, resolveProductIdFunc);
      },
      isEuropatipset: function(anyInput) {
        return isEuropatipset(anyInput, productIds, resolveProductIdFunc);
      },
      isOddset: function(anyInput) {
        return isOddset(anyInput, productIds, resolveProductIdFunc);
      },
      isTopptipset: function(anyInput) {
        return isTopptipset(anyInput, productIds, resolveProductIdFunc);
      },
      isTopptipsetStryk: function(anyInput) {
        return isTopptipset(anyInput, productIds, resolveProductIdFunc);
      },
      isTopptipsetExtra: function(anyInput) {
        return isTopptipset(anyInput, productIds, resolveProductIdFunc);
      },
      isPowerplay: function(anyInput) {
        return isPowerplay(anyInput, productIds, resolveProductIdFunc);
      },
      isMaltipset: function(anyInput) {
        return isMaltipset(anyInput, productIds, resolveProductIdFunc);
      },
      isBomben: function(anyInput) {
        return isBomben(anyInput, productIds, resolveProductIdFunc);
      },
      isChallenge: function(anyInput) {
        return isChallenge(anyInput, productIds, resolveProductIdFunc);
      },
      isMatchen: function(anyInput) {
        return isMatchen(anyInput, productIds, resolveProductIdFunc);
      },
      isLotto: function(anyInput) {
        return isLotto(anyInput, productIds, resolveProductIdFunc);
      },
      isRacing: function(anyInput) {
        return isRacing(anyInput, productIds, resolveProductIdFunc);
      },
      isHvs: function(anyInput) {
        return isFulltraff(anyInput, productIds, resolveProductIdFunc);
      },
      isFulltraff: function(anyInput) {
        return isFulltraff(anyInput, productIds, resolveProductIdFunc);
      }
    };
  } else {
    resolveProductIdFunc = svs.utils.products.resolveProductId;
    productIds = svs.utils.products.productIds;
    svs.utils.products.helpers = svs.utils.products.helpers || {};
    svs.utils.products.helpers.isStryktipset = function(anyInput) {
      return isStryktipset(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isEuropatipset = function(anyInput) {
      return isEuropatipset(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isOddset = function(anyInput) {
      return isOddset(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isTopptipset = function(anyInput) {
      return isTopptipset(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isTopptipsetStryk = function(anyInput) {
      return isTopptipsetStryk(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isTopptipsetExtra = function(anyInput) {
      return isTopptipsetExtra(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isPowerplay = function(anyInput) {
      return isPowerplay(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isMaltipset = function(anyInput) {
      return isMaltipset(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isBomben = function(anyInput) {
      return isBomben(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isChallenge = function(anyInput) {
      return isChallenge(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isMatchen = function(anyInput) {
      return isMatchen(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isLotto = function(anyInput) {
      return isLotto(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isRacing = function(anyInput) {
      return isRacing(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isHvs = function(anyInput) {
      return isFulltraff(anyInput, productIds, resolveProductIdFunc);
    };
    svs.utils.products.helpers.isFulltraff = function(anyInput) {
      return isFulltraff(anyInput, productIds, resolveProductIdFunc);
    };
  }

  function isStryktipset(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.STRYKTIPSET;
  }

  function isEuropatipset(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.EUROPATIPSET;
  }

  function isPowerplay(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.POWERPLAY;
  }

  function isOddset(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.ODDSET;
  }

  function isTopptipset(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return (
      productId === productIds.T8 ||
      productId === productIds.T8STRYK ||
      productId === productIds.T8EXTRA
    );
  }

  function isTopptipsetStryk(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.T8STRYK;
  }

  function isTopptipsetExtra(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.T8EXTRA;
  }

  function isMaltipset(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.MALTIPSET;
  }

  function isBomben(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.BOMBEN;
  }

  function isChallenge(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.CHALLENGE;
  }

  function isMatchen(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.MATCHEN;
  }

  function isLotto(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return (
      productId === productIds.LOTTO ||
      productId === productIds.LOTTO_LOR ||
      productId === productIds.LOTTO_ONS
    );
  }

  function isRacing(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.RACING;
  }

  function isFulltraff(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);
    if (!productId) {
      return false;
    }

    return productId === productIds.HVS;
  }
})(svs);


 })(window);