(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/get-product-classname.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/get-product-classname.js');
(function(svs) {
  var path; var productIds; var resolveProductIdFunc;

  if (typeof window === 'undefined') {
    path = require('path');
    resolveProductIdFunc = require(path.join(__dirname, 'resolve-product-id'));
    productIds = require(path.join(__dirname, 'product-ids'));
    module.exports = function(anyInput) {
      return getProductClassName(anyInput, productIds, resolveProductIdFunc);
    };
  } else {
    resolveProductIdFunc = svs.utils.products.resolveProductId;
    productIds = svs.utils.products.productIds;
    svs.utils.products.getProductClassName = function(anyInput) {
      return getProductClassName(anyInput, productIds, resolveProductIdFunc);
    };
  }

  function getProductClassName(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);

    if (!productId) {
      return '';
    }

    switch (productId) {
      case productIds.STRYKTIPSET:
        return 'stryktipset';
      case productIds.EUROPATIPSET:
        return 'europatipset';
      case productIds.BOMBEN:
        return 'bomben';
      case productIds.MALTIPSET:
        return 'maltipset';
      case productIds.T8STRYK:
      case productIds.T8EXTRA:
      case productIds.T8:
        return 'topptipset';
      case productIds.POWERPLAY:
        return 'powerplay';
      case productIds.CHALLENGE:
        return 'challenge';
      case productIds.MATCHEN:
        return 'matchen';
      case productIds.EUROJACKPOT:
        return 'eurojackpot';
      case productIds.KENO:
        return 'keno';
      case productIds.VIKINGLOTTO:
        return 'vikinglotto';
      case productIds.LOTTO_ONS:
      case productIds.LOTTO_LOR:
      case productIds.LOTTO:
        return 'lotto';
      case productIds.RACING:
        return 'hastar';
      case productIds.ODDSET:
        return 'oddset';
      case productIds.HVS:
      case productIds.FULLTRAFF:
        return 'fulltraff';
      case productIds.SPORTKRYSS:
        return 'sportkryss';
      default:
    }
  }
})(svs);


 })(window);