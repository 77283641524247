(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/resolve-product-id.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/resolve-product-id.js');

(function(svs) {
  var path;
  var productIds;

  if (typeof window === 'undefined') {
    path = require('path');
    productIds = require(path.join(__dirname, 'product-ids'));

    module.exports = function(input) {
      return resolveProductId(input, productIds);
    };
  } else {
    productIds = svs.utils.products.productIds;
    svs.utils.products.resolveProductId = function(input) {
      return resolveProductId(input, productIds);
    };
  }

  function resolveProductId(input, productIds) {
    if (typeof input === 'string') {
      input = input.trim().toLowerCase();
    }

    switch (input) {
      case 'stryk':
      case 'stryktipset':
      case productIds.STRYKTIPSET:
        return productIds.STRYKTIPSET;
      case 'ita':
      case 'europatipset':
      case productIds.EUROPATIPSET:
        return productIds.EUROPATIPSET;
      case 'matchen':
      case productIds.MATCHEN:
        return productIds.MATCHEN;
      case 'bomben':
      case productIds.BOMBEN:
        return productIds.BOMBEN;
      case 'maltipset':
      case 'måltipset':
      case 'mal':
      case productIds.MALTIPSET:
        return productIds.MALTIPSET;
      case 'keno':
      case productIds.KENO:
        return productIds.KENO;
      case 'lorlotto':
      case 'lor lotto':
      case 'lordagslotto':
      case 'lordags lotto':
      case 'lördagslotto':
      case 'lördags lotto':
      case 'lottosaturday':
      case productIds.LORLOTTO:
        return productIds.LORLOTTO;
      case 'onslotto':
      case 'ons lotto':
      case 'onsdagslotto':
      case 'onsdags lotto':
      case 'lottowednesday':
      case productIds.ONSLOTTO:
        return productIds.ONSLOTTO;
      case 'viking':
      case 'vikinglotto':
      case productIds.VIKINGLOTTO:
        return productIds.VIKINGLOTTO;
      case 'oddset':
      case 'ox':
      case 'mixen':
      case productIds.ODDSET:
        return productIds.ODDSET;
      case 't8stryk':
      case 'topptipset stryk':
      case 'topptipsetstryk':
      case productIds.T8STRYK:
        return productIds.T8STRYK;
      case 't8extra':
      case 'topptipset extra':
      case 'topptipset europa':
      case 'topptipsetextra':
      case 'topptipseteuropa':
      case productIds.T8EXTRA:
        return productIds.T8EXTRA;
      case 't8':
      case 'topptipset':
      case productIds.T8:
        return productIds.T8;
      case 'trav-galopp':
      case 'travochgalopp':
      case 'trav&galopp':
      case 'trav & galopp':
      case 'racing':
      case 'rx':
      case 'hastar':
      case 'hästar':
      case productIds.RACING:
        return productIds.RACING;
      case 'powerplay':
      case productIds.POWERPLAY:
        return productIds.POWERPLAY;
      case 'elotto':
      case 'eurojackpot':
      case productIds.EUROJACKPOT:
        return productIds.EUROJACKPOT;
      case 'tmix':
      case 'challenge':
      case productIds.CHALLENGE:
        return productIds.CHALLENGE;
      case 'lotto':
      case productIds.LOTTO:
        return productIds.LOTTO;
      case 'antmal':
      case 'hvs':
      case 'plingjakten':
      case 'fulltraff':
      case 'fullträff':
      case productIds.HVS:
      case productIds.FULLTRAFF:
        return productIds.FULLTRAFF;
      case 'sportkryss':
      case 'sportjoker':
      case productIds.SPORTKRYSS:
        return productIds.SPORTKRYSS;
      default:
    }
  }
})(svs);


 })(window);