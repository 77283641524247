(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/get-draw-name.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/get-draw-name.js');

(function(svs) {
  var path; var productIds; var resolveProductIdFunc;

  if (typeof window === 'undefined') {
    path = require('path');
    resolveProductIdFunc = require(path.join(__dirname, 'resolve-product-id'));
    productIds = require(path.join(__dirname, 'product-ids'));
    module.exports = function(anyInput) {
      return getDrawName(anyInput, productIds, resolveProductIdFunc);
    };
  } else {
    resolveProductIdFunc = svs.utils.products.resolveProductId;
    productIds = svs.utils.products.productIds;
    svs.utils.products.getDrawName = function(anyInput) {
      return getDrawName(anyInput, productIds, resolveProductIdFunc);
    };
  }

  function getDrawName(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);

    if (!productId) {
      return '';
    }

    switch (productId) {
      case productIds.STRYKTIPSET:
        return 'stryktipset';
      case productIds.EUROPATIPSET:
        return 'europatipset';
      case productIds.BOMBEN:
        return 'bomben';
      case productIds.MALTIPSET:
        return 'maltipset';
      case productIds.LOTTO:
        return 'lotto';
      case productIds.T8STRYK:
      case productIds.T8EXTRA:
      case productIds.T8:
        return 'topptipsetfamily';
      case productIds.POWERPLAY:
        return 'powerplay';
      case productIds.CHALLENGE:
        return 'challenge';
      case productIds.MATCHEN:
        return 'matchen';
      case productIds.HVS:
      case productIds.FULLTRAFF:
        return 'fulltraff';
      default:
    }
  }
})(svs);



 })(window);