(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/get-specific-wager-name.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/get-specific-wager-name.js');
"use strict";


(function (svs) {
  let path;
  let productIds;
  let resolveProductIdFunc;
  if (typeof window === 'undefined') {
    path = require('path');
    resolveProductIdFunc = require(path.join(__dirname, 'resolve-wager-product-id'));
    productIds = require(path.join(__dirname, 'wager-product-ids'));
    module.exports = function (anyInput) {
      return getSpecificWagerName(anyInput, productIds, resolveProductIdFunc);
    };
  } else {
    resolveProductIdFunc = svs.utils.products.resolveWagerProductId;
    productIds = svs.utils.products.wagerProductIds;
    svs.utils.products.getSpecificWagerName = function (anyInput) {
      return getSpecificWagerName(anyInput, productIds, resolveProductIdFunc);
    };
  }

  function getSpecificWagerName(anyInput, productIds, resolveProductId) {
    const productId = resolveProductId(anyInput);
    if (!productId) {
      return '';
    }
    switch (productId) {
      case productIds.RACING:
        return 'racing';
      case productIds.HVS:
      case productIds.FULLTRAFF:
        return 'fulltraff';
      default:
    }
  }
})(svs);

 })(window);