(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/product-ids.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/product-ids.js');

(function(svs) {
  var productIds = {
    STRYKTIPSET: 1,
    EUROPATIPSET: 2,
    MATCHEN: 6,
    BOMBEN: 7,
    MALTIPSET: 8,
    KENO: 9,
    LOTTO_LOR: 10,
    LOTTO_ONS: 11,
    VIKINGLOTTO: 14,
    ODDSET: 22,
    T8STRYK: 23,
    T8EXTRA: 24,
    T8: 25,
    POWERPLAY: 28,
    EUROJACKPOT: 29,
    CHALLENGE: 45,
    LOTTO: 1001,
    RACING: 33,
    HVS: 38,
    SPORTKRYSS: 30,
    FULLTRAFF: 38
  };

  if (typeof window === 'undefined') {
    module.exports = productIds;
  } else {
    svs.utils.products.productIds = productIds;
  }
})(svs);


 })(window);