(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/is-tipset-product.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/is-tipset-product.js');

(function(svs) {
  var path; var productIds; var resolveProductIdFunc;

  if (typeof window === 'undefined') {
    path = require('path');
    resolveProductIdFunc = require(path.join(__dirname, 'resolve-product-id'));
    productIds = require(path.join(__dirname, 'product-ids'));
    module.exports = function(anyInput) {
      return isTipsetProduct(anyInput, productIds, resolveProductIdFunc);
    };
  } else {
    resolveProductIdFunc = svs.utils.products.resolveProductId;
    productIds = svs.utils.products.productIds;
    svs.utils.products.isTipsetProduct = function(anyInput) {
      return isTipsetProduct(anyInput, productIds, resolveProductIdFunc);
    };
  }

  function isTipsetProduct(anyInput, productIds, resolveProductId) {
    var productId = resolveProductId(anyInput);

    if (!productId) {
      return false;
    }

    switch (productId) {
      case productIds.STRYKTIPSET:
      case productIds.EUROPATIPSET:
      case productIds.T8STRYK:
      case productIds.T8EXTRA:
      case productIds.T8:
      case productIds.POWERPLAY:
      case productIds.HVS:
      case productIds.FULLTRAFF:
        return true;
      default:
        return false;
    }
  }
})(svs);


 })(window);