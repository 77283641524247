(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/get-display-name.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/get-display-name.js');

var path; var productIds; var resolveProductIdFunc;

if (typeof window === 'undefined') {
  path = require('path');
  resolveProductIdFunc = require(path.join(__dirname, 'resolve-product-id'));
  productIds = require(path.join(__dirname, 'product-ids'));
  module.exports = function(anyInput) {
    return getDisplayName(anyInput, productIds, resolveProductIdFunc);
  };
} else {
  resolveProductIdFunc = svs.utils.products.resolveProductId;
  productIds = svs.utils.products.productIds;
  svs.utils.products.getDisplayName = function(anyInput) {
    return getDisplayName(anyInput, productIds, resolveProductIdFunc);
  };
}
function getDisplayName(anyInput, productIds, resolveProductId) {
  var productId = resolveProductId(anyInput);

  if (!productId) {
    return '';
  }

  switch (productId) {
    case productIds.STRYKTIPSET:
      return 'Stryktipset';
    case productIds.EUROPATIPSET:
      return 'Europatipset';
    case productIds.BOMBEN:
      return 'Bomben';
    case productIds.MALTIPSET:
      return 'Måltipset';
    case productIds.LOTTO:
    case productIds.LOTTO_ONS:
    case productIds.LOTTO_LOR:
      return 'Lotto';
    case productIds.T8STRYK:
    case productIds.T8EXTRA:
    case productIds.T8:
      return 'Topptipset';
    case productIds.POWERPLAY:
      return 'Powerplay';
    case productIds.CHALLENGE:
      return 'Challenge';
    case productIds.MATCHEN:
      return 'Matchen';
    case productIds.EUROJACKPOT:
      return 'Eurojackpot';
    case productIds.KENO:
      return 'Keno';
    case productIds.VIKINGLOTTO:
      return 'Vikinglotto';
    case productIds.RACING:
      return 'Hästar';
    case productIds.ODDSET:
      return 'Oddset';
    case productIds.FULLTRAFF:
      return 'Fullträff';
    default:
  }
}



 })(window);