(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/products.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/products.js');
'use strict';

var svs = svs || {};

var products = {
  1: {
    ITSName: 'STRYK',
    prodname: 'stryktipset'
  },
  2: {
    ITSName: 'ITA',
    prodname: 'europatipset'
  },
  6: {
    ITSName: 'MATCHEN',
    prodname: 'matchen'
  },
  7: {
    ITSName: 'BOMBEN',
    prodname: 'bomben'
  },
  8: {
    ITSName: 'MAL',
    prodname: 'maltipset'
  },
  9: {
    ITSName: 'Keno',
    prodname: 'keno'
  },
  10: {
    ITSName: 'Lorlotto',
    prodname: 'lorlotto'
  },
  11: {
    ITSName: 'Onslotto',
    prodname: 'onslotto'
  },
  14: {
    ITSName: 'Viking',
    prodname: 'vikinglotto'
  },
  22: {
    ITSName: 'Ox',
    prodname: 'oddset'
  },
  23: {
    ITSName: 'T8STRYK',
    prodname: 'topptipset'
  },
  24: {
    ITSName: 'T8EXTRA',
    prodname: 'topptipset'
  },
  25: {
    ITSName: 'T8',
    prodname: 'topptipset'
  },
  26: {
    ITSName: 'BOMBENX',
    prodname: 'bomben'
  },
  28: {
    ITSName: 'POWERPLAY',
    prodname: 'powerplay'
  },
  29: {
    ITSName: 'Elotto',
    prodname: 'eurojackpot'
  },
  30: {
    ITSName: 'SPORTKRYSS',
    prodname: 'sportkryss'
  }, 
  33: {
    ITSName: 'RX',
    prodname: 'travochgalopp'
  },
  37: {
    ITSName: 'Telotto',
    prodname: 'eurojackpot'
  },
  38: {
    ITSName: 'ANTMAL',
    prodname: 'fulltraff'
  }, 
  45: {
    ITSName: 'TMIX',
    prodname: 'challenge'
  }, 
  1001: { 
    ITSName: 'LOTTO',
    prodname: 'lotto'
  }
};

svs.utils = svs.utils || {};
svs.utils.products = svs.utils.products || {};

svs.utils.products.getProdName = function(id) {
  return products[id] ? products[id].prodname : undefined;
};

svs.utils.products.getProdNameByITSName = function(ITSName) {
  if (typeof ITSName !== 'string') {
    throw new Error('First argument must be of type string');
  }

  for (var id in products) {
    if (products[id]) {
      if (
        products[id].ITSName &&
        typeof products[id].ITSName === 'string' &&
        products[id].ITSName.toLowerCase() === ITSName.toLowerCase()
      ) {
        return products[id].prodname;
      }
    }
  }

  throw new Error("Couldn't find product name from ITS name: " + ITSName);
};

svs.utils.products.resolveITSProductId = function(productId) {
  if (typeof productId === 'string') {
    return products[svs.utils.products.resolveProductId(productId)].ITSName;
  }
  if (typeof productId === 'number') {
    return products[productId].ITSName;
  }
  return productId;
};

if (typeof exports === 'object') {
  module.exports = svs.utils.products;
}


 })(window);