(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/resolve-wager-product-id.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/resolve-wager-product-id.js');
"use strict";


(function (svs) {
  let path;
  let wagerProductIds;
  if (typeof window === 'undefined') {
    path = require('path');
    wagerProductIds = require(path.join(__dirname, 'wager-product-ids'));
    module.exports = function (input) {
      return resolveWagerProductId(input, wagerProductIds);
    };
  } else {
    wagerProductIds = svs.utils.products.wagerProductIds;
    svs.utils.products.resolveWagerProductId = function (input) {
      return resolveWagerProductId(input, wagerProductIds);
    };
  }

  function resolveWagerProductId(input, wagerProductIds) {
    if (typeof input === 'string') {
      input = input.trim().toLowerCase();
    }
    switch (input) {
      case wagerProductIds.RACING:
        return wagerProductIds.RACING;
      default:
    }
  }
})(svs);

 })(window);