(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/products/assets/javascripts/wager-product-ids.js') >= 0) return;  svs.modules.push('/components/utils/products/assets/javascripts/wager-product-ids.js');
"use strict";

(function (svs) {
  const productIds = {
    RACING: 'rx'
  };
  if (typeof window === 'undefined') {
    module.exports = productIds;
  } else {
    svs.utils.products.wagerProductIds = productIds;
  }
})(svs);

 })(window);